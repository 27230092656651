import React, { useEffect, useState } from "react";

import SearchBar from "../../components/SearchBar/SearchBar";
import Carousel from "../../components/Carousel/Carousel";

import { useDispatch } from "react-redux";
import { cleanSearch } from "../../actions/searchActions";

import Axios from "../../services/Api";
import app_params from "../../config/params";
// import { library_info } from "../../data/homeInfo";

const HomeScreen = (props) => {
  const [lastAcquisitions, setLastAcquisitions] = useState({
    records: [],
    hasDetailPage: false,
  });
  const [visitCount, setVisitCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cleanSearch());
  }, [dispatch]);

  useEffect(() => {
    Axios.GetLatestAcquisitions()
      .then((res) => res.data)
      .then((data) =>
        setLastAcquisitions(
          data?.success ? data : { records: [], hasDetailPage: false }
        )
      )
      .catch((e) => {
        console.log(e);
        setLastAcquisitions({ records: [], hasDetailPage: false });
      });

    Axios.GetVisits()
      .then((res) => res.data)
      .then((data) => setVisitCount(data?.success ? data?.count : 0))
      .catch((e) => {
        console.log(e);
        setVisitCount(0);
      });
  }, []);

  return (
    <section className="container">
      <div className="bg-white mx-0 mx-md-3 my-3 px-3 px-md-4 px-xl-5 py-5 shadow-sm rounded">
        <div className="row col-12 d-flex justify-content-center mb-2">
          <div className="col-12 col-lg-8 col-xl-6">
            <form action={`${app_params.basepath}/query`}>
              <SearchBar />
            </form>
          </div>
        </div>
        <div className="text-center">
          <a href={`${app_params.basepath}/advancedsearch`}>
            Búsqueda avanzada
          </a>
        </div>
      </div>
      <Carousel
        title={"Novedades"}
        items={lastAcquisitions?.records}
        hasDetailPage={lastAcquisitions?.hasDetailPage}
      />
      {/* <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded">
        <div
          className="row p-2"
          dangerouslySetInnerHTML={{ __html: library_info.description }}
        ></div>
      </div> */}
      <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded">
        <div className="row p-2">
          <div className="col-12 text-center">
            Usted es el visitante # {visitCount}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeScreen;
